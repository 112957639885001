import React, { FC, ReactElement, useEffect, useState } from 'react';
import SearchField from '../../elements/SearchField';
import { UiConstants } from '../constants';
import './ConsoleAreaConsoleSetsDataView.scss';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../components/Dialog';
import Pagination from '../components/Pagination';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import { Switch } from '../../elements/Switch';
import {
  Console,
  ConsoleSet,
  ConsoleSetApplications,
  DriveFamily,
} from '../../redux/admin/adminFacadeReducer';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  changeConsoleSet,
  createConsoleSet,
} from '../../redux/admin/adminFacadeActions';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SelectField } from '../../elements/SelectField';
import {
  DriveSeries,
  DriveType,
  OpeningType,
  RangeOfApplication,
} from '../../redux/constants';
import AdminRadioButton from '../../elements/RadioButton';
import CheckBox from '../../elements/CheckBox';
import { fieldsFilled } from '../general/helpers';
import TableRow from '../elements/TableRow';
import TableHeader from '../elements/TableHeader';
import Table from '../components/Table';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { useAdminSearch } from '../hooks';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';
import { every } from 'lodash';
import { AssignSelectGroup } from '../components/AssignSelectGroup';

const ConsoleSetDataDialogTabNav: FC<
  React.PropsWithChildren<{
    rangeOfApplication: RangeOfApplication;
  }>
> = props => {
  return (
    <div className="admin__tab-nav console-set-data-view-tab-nav">
      <AdminNavLink
        to={
          '/admin/' +
          props.rangeOfApplication.toLowerCase() +
          '/consoles/console-sets/general'
        }
      >
        Allgemein
      </AdminNavLink>
      <AdminNavLink
        to={
          '/admin/' +
          props.rangeOfApplication.toLowerCase() +
          '/consoles/console-sets/properties'
        }
      >
        Eigenschaften
      </AdminNavLink>
      <AdminNavLink
        to={
          '/admin/' +
          props.rangeOfApplication.toLowerCase() +
          '/consoles/console-sets/eckdaten'
        }
      >
        Eckdaten
      </AdminNavLink>{' '}
    </div>
  );
};

interface ConsoleSetMeasurementDataDialogProps {
  spaceRequirementSashProfile: number | null | undefined;
  setSpaceRequirementSashProfile: (i: number | null) => void;
  spaceRequirementFrameProfile: number | null | undefined;
  setSpaceRequirementFrameProfile: (i: number | null) => void;
  minSpaceBetweenSets: number | null | undefined;
  setMinSpaceBetweenSets: (i: number | null) => void;
  montageplatzInklLagerschraube: number | null | undefined;
  setMontageplatzInklLagerschraube: (i: number | null) => void;
  abstandFluegelaussenkanteZumAntriebB3: number | null | undefined;
  setAbstandFluegelaussenkanteZumAntriebB3: (i: number | null) => void;
  cornerAngleLength: number | null | undefined;
  setCornerAngleLength: (i: number | null) => void;
}

const ConsoleSetMeasurementDataDialog: FC<
  React.PropsWithChildren<ConsoleSetMeasurementDataDialogProps>
> = (props: ConsoleSetMeasurementDataDialogProps) => {
  return (
    <FormLayout additionalClass="console-set-data-view__console-set-measurement-data">
      <FormLayoutSubgroupTitle additionalClassNames="form-layout__input-field-title console-set-data-view__space-sash">
        Platzbedarf auf dem Flügelrahmen (mm) *
      </FormLayoutSubgroupTitle>
      <InputFieldNumber
        additionalClass="console-set-data-view__space-sash"
        placeholder="z.B. 42"
        value={props.spaceRequirementSashProfile}
        id="1_input_measurement"
        onChange={props.setSpaceRequirementSashProfile}
      />
      <div className="form-layout__sub-group-title form-layout__input-field-title console-set-data-view__space-frame">
        Platzbedarf auf dem Blendrahmen (mm) *
      </div>
      <InputFieldNumber
        additionalClass="console-set-data-view__space-frame"
        placeholder="z.B. 42"
        value={props.spaceRequirementFrameProfile}
        onChange={props.setSpaceRequirementFrameProfile}
      />
      <FormLayoutSubgroupTitle additionalClassNames="form-layout__input-field-title console-set-data-view__space-bt-sets">
        min. Abstand zw. Sets (mm) *
      </FormLayoutSubgroupTitle>
      <InputFieldNumber
        additionalClass="console-set-data-view__space-bt-sets"
        placeholder="z.B. 42"
        value={props.minSpaceBetweenSets}
        id="3"
        onChange={props.setMinSpaceBetweenSets}
      />
      <div className="form-layout__sub-group-title form-layout__input-field-title console-set-data-view__lagerschraube">
        Montageplatz inkl. Lagerschraube *
      </div>
      <InputFieldNumber
        additionalClass="console-set-data-view__lagerschraube"
        placeholder="z.B. 42"
        value={props.montageplatzInklLagerschraube}
        id="4"
        onChange={props.setMontageplatzInklLagerschraube}
      />
      <div className="form-layout__input-field-title console-set-data-view__distance-sash-drive">
        Abstand Flügelaußenkante zum Antrieb b3 (mm) *
      </div>
      <InputFieldNumber
        additionalClass="console-set-data-view__distance-sash-drive"
        placeholder="z.B. 42"
        value={props.abstandFluegelaussenkanteZumAntriebB3}
        id="4"
        onChange={props.setAbstandFluegelaussenkanteZumAntriebB3}
      />
      <div className="form-layout__input-field-title console-set-data-view__corner-angle-length">
        Eckwinkellänge (mm)
      </div>
      <InputFieldNumber
        additionalClass="console-set-data-view__corner-angle-length"
        placeholder="z.B. 42"
        value={props.cornerAngleLength}
        id="4"
        onChange={props.setCornerAngleLength}
      />
    </FormLayout>
  );
};

interface ConsoleSetPropertiesDataDialogProps {
  setMaxOpeningAngle: (angle: number | undefined) => void;
  maxOpeningAngle: number | undefined;
  freeEntry: boolean;
  setFreeEntry: (b: boolean) => void;
  selectedConsoleSet: ConsoleSet | undefined;
  isAluminium: boolean;
  setIsAluminium: (b: boolean) => void;
  isWood: boolean;
  setIsWood: (b: boolean) => void;
  isGlass: boolean;
  setIsGlass: (b: boolean) => void;
  isSteel: boolean;
  setIsSteel: (b: boolean) => void;
  isWoodAluminium: boolean;
  setIsWoodAluminium: (b: boolean) => void;
  isPlastic: boolean;
  setIsPlastic: (b: boolean) => void;
  driveFamilies: DriveFamily[] | undefined;
  setDriveFamilies: (f: DriveFamily[]) => void;
  driveSeries: DriveSeries[] | undefined;
  setDriveSeries: (s: DriveSeries[]) => void;
  isAssemblyPositionFrame: boolean;
  setIsAssemblyPositionFrame: (b: boolean) => void;
  isAssemblyPositionSash: boolean;
  setIsAssemblyPositionSash: (b: boolean) => void;
  isOpeningDirectionInwards: boolean;
  setIsOpeningDirectionInwards: (b: boolean) => void;
  isOpeningDirectionOutwards: boolean;
  setIsOpeningDirectionOutwards: (b: boolean) => void;
  isMontageOnTop: boolean;
  setIsMontageOnTop: (b: boolean) => void;
  isAssemblyIntegrated: boolean;
  setIsAssemblyIntegrated: (b: boolean) => void;
  isAssemblyRotatable: boolean;
  setIsAssemblyRotatable: (b: boolean) => void;
  withGasSpring: boolean;
  setWithGasSpring: (b: boolean) => void;
  consoleSetApplications: ConsoleSetApplications;
  setConsoleSetApplications: (c: ConsoleSetApplications) => void;
  requiresVCDTailPiece: boolean;
  setRequiresVCDTailPiece: (b: boolean) => void;
}

function ConsoleSetPropertiesDataDialog(
  props: ConsoleSetPropertiesDataDialogProps,
): ReactElement {
  const allDriveSeries = useSelector<AdminState, DriveSeries[]>(
    s => s.adminFacade.driveSeries,
  );
  const allDriveFamilies = useSelector<AdminState, DriveFamily[]>(
    s => s.adminFacade.driveFamilies,
  );

  const suitableDriveFamilies = [
    // 'Alle Antriebsfamilien',
    ...allDriveFamilies
      .filter(df => df.active)
      .filter(df =>
        props.driveSeries
          ?.map(ds => ds.id)
          .some(ds => ds === df.driveSeries.id),
      ),
  ];

  function updateConsoleSetApplication(
    newConsoleSetApplications: ConsoleSetApplications,
  ) {
    props.setConsoleSetApplications({
      ...props.consoleSetApplications,
      ...newConsoleSetApplications,
    });
  }

  function toggleConsoleSetApplication(openingType: OpeningType) {
    if (props.consoleSetApplications[openingType]) {
      const newConsoleSetApplications = { ...props.consoleSetApplications };
      delete newConsoleSetApplications[openingType];
      props.setConsoleSetApplications(newConsoleSetApplications);
    } else {
      props.setConsoleSetApplications({
        ...props.consoleSetApplications,
        [openingType]: { sideInstallation: false, sideOppositeTheHinge: false },
      });
    }
  }

  function switchProperty(target: string): void {
    if (target === 'assemblyPos') {
      props.setIsAssemblyPositionFrame(!props.isAssemblyPositionFrame);
      props.setIsAssemblyPositionSash(!props.isAssemblyPositionSash);
    }

    if (target === 'openDir') {
      props.setIsOpeningDirectionInwards(!props.isOpeningDirectionInwards);
      props.setIsOpeningDirectionOutwards(!props.isOpeningDirectionOutwards);
    }

    if (target === 'assembly') {
      props.setIsAssemblyIntegrated(!props.isAssemblyIntegrated);
      props.setIsMontageOnTop(!props.isMontageOnTop);
    }
  }

  return (
    <FormLayout additionalClass="console-set-data-view__console-set-properties-data">
      <FormLayoutElement rowStart={2} columnStart={1} columnWidth={6}>
        <FormLayout inline={true}>
          <FormLayoutElement rowStart={1} columnStart={1} columnWidth={6}>
            <FormLayoutSubgroupTitle>Material *</FormLayoutSubgroupTitle>
          </FormLayoutElement>
          <FormLayoutElement rowStart={2} columnStart={1} columnWidth={1}>
            <Switch
              labelText="Aluminium"
              turnedOn={props.isAluminium || false}
              onChange={props.setIsAluminium}
            />
          </FormLayoutElement>
          <FormLayoutElement rowStart={3} columnStart={1} columnWidth={1}>
            <Switch
              labelText="Holz-Aluminium"
              turnedOn={props.isWoodAluminium || false}
              onChange={props.setIsWoodAluminium}
            />
          </FormLayoutElement>

          <FormLayoutElement rowStart={2} columnStart={3} columnWidth={1}>
            <Switch
              labelText="Holz"
              turnedOn={props.isWood || false}
              onChange={props.setIsWood}
            />
          </FormLayoutElement>
          <FormLayoutElement rowStart={2} columnStart={2} columnWidth={1}>
            <Switch
              labelText="Kunststoff"
              turnedOn={props.isPlastic || false}
              onChange={props.setIsPlastic}
            />
          </FormLayoutElement>

          <FormLayoutElement rowStart={3} columnStart={2} columnWidth={1}>
            <Switch
              labelText="Stahl"
              turnedOn={props.isSteel || false}
              onChange={props.setIsSteel}
            />
          </FormLayoutElement>
          <FormLayoutElement rowStart={3} columnStart={3} columnWidth={1}>
            <Switch
              labelText="Ganzglassystem"
              turnedOn={props.isGlass || false}
              onChange={props.setIsGlass}
            />
          </FormLayoutElement>
        </FormLayout>
      </FormLayoutElement>

      <FormLayoutElement
        rowStart={1}
        columnStart={1}
        columnWidth={2}
        rowHeight={1}
      >
        {/*<FormLayoutSubgroupTitle>Antriebsserie *</FormLayoutSubgroupTitle>*/}
        <AssignSelectGroup
          componentClass="console-set-data-view__console-set-properties-data-series"
          multipleSelectAllowed={true}
          headings={{
            componentHeadingWithoutDialog: 'Antriebsserien *',
            headingLeftTable: 'nicht ausgewählt',
            headingRightTable: 'ausgewählt',
          }}
          addElements={(driveSeries: DriveSeries[]) =>
            props.setDriveSeries([...driveSeries, ...props.driveSeries!])
          }
          removeElements={(driveSeries: DriveSeries[]) =>
            props.setDriveSeries(
              props.driveSeries!.filter(f => !driveSeries.includes(f)),
            )
          }
          selectedElements={props.driveSeries!}
          deselectedElements={allDriveSeries.filter(
            f => !props.driveFamilies!.map(f2 => f2.name).includes(f.name),
          )}
          displayNameExtractor={(f: DriveSeries) => f.name}
          backgroundWhite={true}
        />
      </FormLayoutElement>
      <FormLayoutElement
        rowStart={1}
        columnStart={3}
        columnWidth={2}
        rowHeight={1}
      >
        <AssignSelectGroup<string | DriveFamily>
          // key={props.driveFamilies!.length}
          componentClass="console-set-data-view__console-set-properties-data-series"
          multipleSelectAllowed={true}
          headings={{
            componentHeadingWithoutDialog: 'Antriebsfamilien *',
            headingLeftTable: 'nicht ausgewählt',
            headingRightTable: 'ausgewählt',
          }}
          addElements={(driveFamilies: (DriveFamily | string)[]) => {
            props.setDriveFamilies([
              ...(driveFamilies as DriveFamily[]),
              ...props.driveFamilies!,
            ]);
          }}
          removeElements={(driveFamilies: (DriveFamily | string)[]) => {
            props.setDriveFamilies(
              props.driveFamilies!.filter(f => !driveFamilies.includes(f)),
            );
          }}
          selectedElements={
            props.driveFamilies!.length === 0
              ? ['Alle Antriebsfamilien']
              : props.driveFamilies!
          }
          deselectedElements={suitableDriveFamilies.filter(
            df => !props.driveFamilies?.map(df => df.id)!.includes(df.id),
          )}
          displayNameExtractor={(f: DriveFamily | string) =>
            typeof f === 'string' ? f : f.antriebsfamilie
          }
          backgroundWhite={true}
        />
      </FormLayoutElement>

      <FormLayoutElement rowStart={3} columnStart={1} columnWidth={1}>
        <FormLayoutSubgroupTitle>Montageposition *</FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={props.isAssemblyPositionFrame}
          name="Rahmenmontage"
          onChange={() => switchProperty('assemblyPos')}
        />
        <AdminRadioButton
          checked={props.isAssemblyPositionSash}
          name="Flügelmontage"
          onChange={() => switchProperty('assemblyPos')}
        />
      </FormLayoutElement>

      <FormLayoutElement rowStart={3} columnStart={2} columnWidth={1}>
        <FormLayoutSubgroupTitle>Öffnungsrichtung *</FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={props.isOpeningDirectionInwards}
          name="Einwärts"
          onChange={() => switchProperty('openDir')}
        />
        <AdminRadioButton
          checked={props.isOpeningDirectionOutwards}
          name="Auswärts"
          onChange={() => switchProperty('openDir')}
        />
      </FormLayoutElement>

      <FormLayoutElement rowStart={3} columnStart={3} columnWidth={1}>
        <FormLayoutSubgroupTitle>Montage *</FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={props.isAssemblyIntegrated}
          name="Integriert"
          onChange={() => switchProperty('assembly')}
        />
        <AdminRadioButton
          checked={props.isMontageOnTop}
          name="Aufgesetzt"
          onChange={() => switchProperty('assembly')}
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={3} columnStart={4} columnWidth={1}>
        <FormLayoutSubgroupTitle>Drehbare Montage</FormLayoutSubgroupTitle>
        <CheckBox
          labelText="drehbar"
          checked={!!props.isAssemblyRotatable}
          onClick={() =>
            props.setIsAssemblyRotatable(!props.isAssemblyRotatable)
          }
        />
      </FormLayoutElement>

      <FormLayoutElement rowStart={5} columnStart={1} columnWidth={1}>
        <FormLayoutSubgroupTitle>Gasdruckfeder</FormLayoutSubgroupTitle>
        <CheckBox
          labelText="mit Gasdruckfeder"
          checked={!!props.withGasSpring}
          onClick={() => props.setWithGasSpring(!props.withGasSpring)}
        />
      </FormLayoutElement>

      <FormLayoutElement rowStart={5} columnStart={2} columnWidth={1}>
        <FormLayoutSubgroupTitle>
          Berechnung ohne Profile
        </FormLayoutSubgroupTitle>
        <CheckBox
          labelText="möglich"
          checked={!!props.freeEntry}
          onClick={() => props.setFreeEntry(!props.freeEntry)}
        />
      </FormLayoutElement>

      <FormLayoutElement rowStart={6} columnStart={1} columnWidth={5}>
        <FormLayoutSubgroupTitle>Anwendung *</FormLayoutSubgroupTitle>
        <div className="console-set-data-view__applications">
          {Object.values(OpeningType).map((openingType: OpeningType) => (
            <div
              className="console-set-data-view__applications-entry"
              key={openingType}
            >
              <div className="console-set-data-view__applications-entry-switch">
                <Switch
                  label={openingType}
                  turnedOn={!!props.consoleSetApplications?.[openingType]}
                  onChange={() => toggleConsoleSetApplication(openingType)}
                />
              </div>
              {!!props.consoleSetApplications?.[openingType] && (
                <div className="console-set-data-view__applications-entry-side-checkboxes">
                  <CheckBox
                    label="seitlich"
                    checked={
                      props.consoleSetApplications[openingType]!
                        .sideInstallation
                    }
                    onClick={() =>
                      updateConsoleSetApplication({
                        [openingType]: {
                          sideInstallation:
                            !props.consoleSetApplications[openingType]!
                              .sideInstallation,
                          sideOppositeTheHinge:
                            props.consoleSetApplications[openingType]!
                              .sideOppositeTheHinge,
                        },
                      })
                    }
                    additionalClass="console-set-data-view__side-checkbox"
                  />
                  <CheckBox
                    label="bandgegenseite"
                    checked={
                      props.consoleSetApplications[openingType]!
                        .sideOppositeTheHinge
                    }
                    onClick={() =>
                      updateConsoleSetApplication({
                        [openingType]: {
                          sideInstallation:
                            props.consoleSetApplications[openingType]!
                              .sideInstallation,
                          sideOppositeTheHinge:
                            !props.consoleSetApplications[openingType]!
                              .sideOppositeTheHinge,
                        },
                      })
                    }
                    additionalClass="console-set-data-view__side-checkbox"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </FormLayoutElement>
      <FormLayoutElement rowStart={7} columnStart={1} columnWidth={2}>
        <FormLayoutSubgroupTitle>Öffnungswinkel</FormLayoutSubgroupTitle>
        <InputField
          label="max Öffnungswinkel"
          placeholder="z.B. 42.00.42"
          value={props.maxOpeningAngle || ''}
          additionalClass="console-set-data-view__art-nr"
          onChange={angle => props.setMaxOpeningAngle(+angle)}
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={8} columnStart={1} columnWidth={2}>
        <FormLayoutSubgroupTitle>weiter Anforderungen</FormLayoutSubgroupTitle>
        <CheckBox
          labelText="VCD Kettenendstück eforderlich"
          checked={!!props.requiresVCDTailPiece}
          onClick={() =>
            props.setRequiresVCDTailPiece(!props.requiresVCDTailPiece)
          }
        />
      </FormLayoutElement>
    </FormLayout>
  );
}

interface ConsoleSetGeneralDataDialogProps {
  setIsPreview: (b: boolean) => void;
  isPreview: boolean;
  setArtNr: (s: string) => void;
  artNr: string;
  setIsActive: (b: boolean) => void;
  isActive: boolean | undefined;
  name: string;
  setName: (s: string) => void;
  setFrameConsole: (c: Console | undefined) => void;
  frameConsole: Console | undefined;
  setSashConsole: (c: Console | undefined) => void;
  sashConsole: Console | undefined;
  setOnlyForConsoleSetAssignment: (b: boolean) => void;
  onlyForConsoleSetAssignment: boolean | undefined;
  allFrameConsoles: Console[];
  allSashConsoles: Console[];
  selectedConsoleSet: ConsoleSet | undefined;
}

const ConsoleSetGeneralDataDialog: FC<
  React.PropsWithChildren<ConsoleSetGeneralDataDialogProps>
> = (props: ConsoleSetGeneralDataDialogProps) => {
  return (
    <>
      <FormLayout additionalClass="console-set-data-view__console-set-general-data">
        <InputField
          label="Artikelnummer *"
          placeholder="z.B. 42.00.42"
          value={props.artNr || ''}
          additionalClass="console-set-data-view__art-nr"
          onChange={props.setArtNr}
        />
        <InputField
          label="Bezeichnung *"
          placeholder="z.B. D&H's beste"
          value={props.name || ''}
          additionalClass="console-set-data-view__name"
          onChange={props.setName}
        />
        <div className="console-set-data-view__frame-console"></div>
        <SelectField
          additionalClass="console-set-data-view__frame-console"
          label="Rahmenkonsole *"
          value={
            props.frameConsole
              ? {
                  value: props.frameConsole,
                  label: props.frameConsole.artNr,
                }
              : undefined
          }
          action={newValue => {
            props.setFrameConsole(newValue.value);
          }}
          options={props.allFrameConsoles.map(con => ({
            value: con,
            label: con.artNr,
          }))}
          name="sash-console"
          searchable={true}
          placeholder="z.B. Schüco"
        />

        <div className="console-set-data-view__sash-console"></div>
        <SelectField
          additionalClass="console-set-data-view__sash-console"
          label="Flügelkonsole *"
          value={
            props.sashConsole
              ? {
                  value: props.sashConsole,
                  label: props.sashConsole?.artNr,
                }
              : undefined
          }
          action={newValue => {
            props.setSashConsole(newValue.value);
          }}
          options={props.allSashConsoles.map(con => ({
            value: con,
            label: con.artNr,
          }))}
          name="frame-console"
          searchable={true}
          placeholder="z.B. Schüco"
        />

        <div className="console-set-data-view__is-active">
          <Switch
            labelText="Aktiv"
            turnedOn={props.isActive || false}
            onChange={props.setIsActive}
          />
        </div>

        <div className="console-set-data-view__is-preview">
          <Switch
            labelText="Vorschau"
            turnedOn={props.isPreview || false}
            onChange={props.setIsPreview}
          />
        </div>

        <div className="console-set-data-view__only-assignment">
          <Switch
            labelText="Nur bei Konsolenset Zuordnung"
            turnedOn={props.onlyForConsoleSetAssignment || false}
            onChange={props.setOnlyForConsoleSetAssignment}
          />
        </div>
      </FormLayout>
    </>
  );
};

interface AdminConsoleSetDialogProps {
  rangeOfApplication: RangeOfApplication;
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
  selectedConsoleSet: ConsoleSet | undefined;
  setSelectedConsoleSet: (console: ConsoleSet | undefined) => void;
}

function AdminConsoleSetDialog(
  props: AdminConsoleSetDialogProps,
): ReactElement {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const allConsoles = useSelector<AdminState, Console[]>(state =>
    props.rangeOfApplication === RangeOfApplication.FACADE
      ? state.adminFacade.consoles
      : state.adminRoof.consoles,
  );
  //general data
  const [frameConsole, setFrameConsole] = useState(
    props.selectedConsoleSet?.frameConsole,
  );
  const [sashConsole, setSashConsole] = useState(
    props.selectedConsoleSet?.sashConsole,
  );
  const [artNr, setArtNr] = useState(props.selectedConsoleSet?.artNr || '');
  const [name, setName] = useState(props.selectedConsoleSet?.name || '');
  const [onlyConsoleSetAssignment, setOnlyConsoleSetAssignment] = useState(
    props.selectedConsoleSet?.onlyConsoleSetAssignment || false,
  );
  const [isActive, setIsActive] = useState(
    props.selectedConsoleSet?.active || false,
  );
  const [isPreview, setIsPreview] = useState(
    props.selectedConsoleSet?.preview ||
      (props.selectedConsoleSet?.preview === false ? false : true),
  );
  //properties
  const [isAlu, setIsAlu] = useState(
    props.selectedConsoleSet?.aluminium || false,
  );
  const [isWood, setIsWood] = useState(props.selectedConsoleSet?.wood || false);
  const [isSteel, setIsSteel] = useState(
    props.selectedConsoleSet?.steel || false,
  );
  const [isWoodAluminium, setIsWoodAluminium] = useState(
    props.selectedConsoleSet?.woodAluminium || false,
  );
  const [isPlastic, setIsPlastic] = useState(
    props.selectedConsoleSet?.plastic || false,
  );
  const [isGlass, setIsGlass] = useState(
    props.selectedConsoleSet?.glass || false,
  );
  const [driveSeries, setDriveSeries] = useState<DriveSeries[]>(
    props.selectedConsoleSet?.driveSeries ?? [],
  );
  const [driveFamilies, setDriveFamilies] = useState<DriveFamily[]>(
    props.selectedConsoleSet?.driveFamilies ?? [],
  );

  const [isAssemblyPositionFrame, setIsAssemblyPositionFrame] = useState(
    props.selectedConsoleSet?.assemblyPositionFrame ?? false,
  );
  const [isAssemblyPositionSash, setIsAssemblyPositionSash] = useState(
    props.selectedConsoleSet?.assemblyPositionSash ?? false,
  );
  const [consoleSetApplications, setConsoleSetApplications] =
    useState<ConsoleSetApplications>(
      props.selectedConsoleSet?.consoleSetApplications ?? {},
    );
  const [maxOpeningAngle, setMaxOpeningAngle] = useState<number | undefined>(
    props.selectedConsoleSet?.maxOpeningAngle,
  );
  const [requiresVcdTailPiece, setRequiresVcdTailPiece] = useState<boolean>(
    props.selectedConsoleSet?.requiresVcdTailPiece ?? false,
  );
  const [isOpeningDirectionInwards, setIsOpeningDirectionInwards] = useState(
    props.selectedConsoleSet?.openingDirectionInwards ?? false,
  );
  const [isOpeningDirectionOutwards, setIsOpeningDirectionOutwards] = useState(
    props.selectedConsoleSet?.openingDirectionOutwards ?? false,
  );
  const [isAssemblyRotatable, setIsAssemblyRotatable] = useState(
    props.selectedConsoleSet?.assemblyRotatable ?? false,
  );
  const [isAssemblyIntegrated, setIsAssemblyIntegrated] = useState(
    props.selectedConsoleSet?.assemblyIntegrated ?? false,
  );
  const [isMontageOnTop, setIsMontageOnTop] = useState(
    props.selectedConsoleSet?.montageOnTop ?? false,
  );
  const [withGasSpring, setWithGasSpring] = useState(
    props.selectedConsoleSet?.withGasSpring,
  );
  const [freeEntry, setFreeEntry] = useState(
    props.selectedConsoleSet?.freeEntry,
  );

  const [
    abstandFluegelaussenkanteZumAntriebB3,
    setAbstandFluegelaussenkanteZumAntriebB3,
  ] = useState<number | undefined | null>(
    props.selectedConsoleSet?.abstandFluegelaussenkanteZumAntriebB3,
  );
  const [montageplatzInklLagerschraube, setMontageplatzInklLagerschraube] =
    useState<number | undefined | null>(
      props.selectedConsoleSet?.montageplatzInklLagerschraube,
    );
  const [minSpaceBetweenSets, setMinSpaceBetweenSets] = useState<
    number | null | undefined
  >(props.selectedConsoleSet?.minSpaceBetweenSets);
  const [spaceRequirementFrameProfile, setSpaceRequirementFrameProfile] =
    useState<number | undefined | null>(
      props.selectedConsoleSet?.spaceRequirementFrameProfile,
    );
  const [spaceRequirementSashProfile, setSpaceRequirementSashProfile] =
    useState<number | undefined | null>(
      props.selectedConsoleSet?.spaceRequirementSashProfile,
    );
  const [cornerAngleLength, setCornerAngleLength] = useState<
    number | undefined | null
  >(props.selectedConsoleSet?.cornerAngleLength);

  useEffect(() => {
    resetInputFields();
  }, [props.selectedConsoleSet]);

  //initialization of the radiobuttoms for new sets
  useEffect(() => {
    if (!isAssemblyPositionFrame && !isAssemblyPositionSash) {
      setIsAssemblyPositionFrame(true);
      setIsAssemblyPositionSash(false);
    }

    if (!isOpeningDirectionInwards && !isOpeningDirectionOutwards) {
      setIsOpeningDirectionInwards(true);
      setIsOpeningDirectionOutwards(false);
    }
    if (!isAssemblyIntegrated && !isMontageOnTop) {
      setIsAssemblyIntegrated(true);
      setIsMontageOnTop(false);
    }
  }, [
    isAssemblyPositionFrame,
    isAssemblyPositionSash,
    isOpeningDirectionInwards,
    isOpeningDirectionOutwards,
    isAssemblyIntegrated,
    isMontageOnTop,
  ]);

  function allFrameConsoles(): Console[] {
    return allConsoles.filter(c => c.isFrameConsole);
  }

  function allSashConsoles(): Console[] {
    return allConsoles.filter(c => c.isSashConsole);
  }

  function resetInputFields(): void {
    //general data
    setName(props.selectedConsoleSet?.name || '');
    setArtNr(props.selectedConsoleSet?.artNr || '');
    setIsActive(props.selectedConsoleSet?.active || false);
    setIsPreview(
      props.selectedConsoleSet?.preview ||
        (props.selectedConsoleSet?.preview === false ? false : true),
    );
    setOnlyConsoleSetAssignment(
      props.selectedConsoleSet?.onlyConsoleSetAssignment || false,
    );
    //properties data
    setIsAlu(props.selectedConsoleSet?.aluminium || false);
    setIsWood(props.selectedConsoleSet?.wood || false);
    setIsWoodAluminium(props.selectedConsoleSet?.woodAluminium || false);
    setIsSteel(props.selectedConsoleSet?.steel || false);
    setIsPlastic(props.selectedConsoleSet?.plastic || false);
    setIsGlass(props.selectedConsoleSet?.plastic || false);
    setDriveSeries(props.selectedConsoleSet?.driveSeries ?? []);
    setIsAssemblyPositionFrame(
      props.selectedConsoleSet?.assemblyPositionFrame || false,
    );
    setIsAssemblyPositionSash(
      props.selectedConsoleSet?.assemblyPositionSash || false,
    );
    setConsoleSetApplications(
      props.selectedConsoleSet?.consoleSetApplications ?? {},
    );
    setIsOpeningDirectionInwards(
      props.selectedConsoleSet?.openingDirectionInwards || false,
    );
    setIsOpeningDirectionOutwards(
      props.selectedConsoleSet?.openingDirectionOutwards || false,
    );
    setIsAssemblyIntegrated(
      props.selectedConsoleSet?.assemblyIntegrated || false,
    );
    setIsMontageOnTop(props.selectedConsoleSet?.montageOnTop || false);
    setIsAssemblyRotatable(
      props.selectedConsoleSet?.assemblyRotatable || false,
    );
    setSpaceRequirementSashProfile(
      props.selectedConsoleSet?.spaceRequirementSashProfile,
    );
    setSpaceRequirementFrameProfile(
      props.selectedConsoleSet?.spaceRequirementFrameProfile,
    );
    setMinSpaceBetweenSets(props.selectedConsoleSet?.minSpaceBetweenSets);
    setMontageplatzInklLagerschraube(
      props.selectedConsoleSet?.montageplatzInklLagerschraube,
    );
    setAbstandFluegelaussenkanteZumAntriebB3(
      props.selectedConsoleSet?.abstandFluegelaussenkanteZumAntriebB3,
    );
    setFrameConsole(props.selectedConsoleSet?.frameConsole);
    setSashConsole(props.selectedConsoleSet?.sashConsole);
    setWithGasSpring(props.selectedConsoleSet?.withGasSpring);
    setFreeEntry(props.selectedConsoleSet?.freeEntry);
    setCornerAngleLength(props.selectedConsoleSet?.cornerAngleLength);
  }

  function groupOfValuesNotEmpty(...materials: boolean[]): boolean {
    if (materials.every(m => m === false)) {
      return false;
    }
    return true;
  }

  function simpleFieldsFilled(): boolean {
    const res = fieldsFilled(
      artNr,
      name,
      onlyConsoleSetAssignment,
      driveSeries,
      isAssemblyRotatable,
      minSpaceBetweenSets,
      montageplatzInklLagerschraube,
      abstandFluegelaussenkanteZumAntriebB3,
    );
    return res;
  }

  function materialFilled(): boolean {
    return groupOfValuesNotEmpty(
      isAlu,
      isWood,
      isWoodAluminium,
      isSteel,
      isGlass,
      isPlastic,
    );
  }

  function assemblyPositionFilled(): boolean {
    return groupOfValuesNotEmpty(
      isAssemblyPositionFrame,
      isAssemblyPositionSash,
    );
  }

  function openingTypeFilled(): boolean {
    return Object.keys(consoleSetApplications || {}).length > 0;
  }

  function openingDirectionFilled(): boolean {
    return groupOfValuesNotEmpty(
      isOpeningDirectionInwards,
      isOpeningDirectionOutwards,
    );
  }

  function assemblyFilled(): boolean {
    return groupOfValuesNotEmpty(isAssemblyIntegrated, isMontageOnTop);
  }

  function assemblyTypeFilled(): boolean {
    if (!consoleSetApplications) {
      return false;
    }

    return every(
      Object.keys(consoleSetApplications || {}),
      (openingType: OpeningType) =>
        consoleSetApplications[openingType]?.sideInstallation ||
        consoleSetApplications[openingType]?.sideOppositeTheHinge,
    );
  }

  function mandatoryFieldsFilled(): boolean {
    return (
      simpleFieldsFilled() &&
      materialFilled() &&
      assemblyPositionFilled() &&
      openingTypeFilled() &&
      openingDirectionFilled() &&
      assemblyFilled() &&
      assemblyTypeFilled()
    );
  }

  function editConsoleSet(): void {
    if (!driveSeries) {
      throw new Error('Drive series is undefined');
    }

    if (props.selectedConsoleSet) {
      dispatch(
        changeConsoleSet({
          ...props.selectedConsoleSet,
          // @ts-ignore
          legacyId: '1',
          name: name,
          artNr: artNr,
          active: isActive,
          preview: isPreview,
          frameConsole: frameConsole!,
          sashConsole: sashConsole!,
          onlyConsoleSetAssignment: onlyConsoleSetAssignment,
          aluminium: isAlu,
          wood: isWood,
          glass: isGlass,
          steel: isSteel,
          woodAluminium: isWoodAluminium,
          plastic: isPlastic,
          driveSeries: driveSeries,
          driveFamilies: driveFamilies,
          assemblyPositionFrame: isAssemblyPositionFrame,
          assemblyPositionSash: isAssemblyPositionSash,
          openingDirectionInwards: isOpeningDirectionInwards,
          openingDirectionOutwards: isOpeningDirectionOutwards,
          montageOnTop: isMontageOnTop,
          assemblyIntegrated: isAssemblyIntegrated,
          assemblyRotatable: isAssemblyRotatable,
          spaceRequirementSashProfile: spaceRequirementSashProfile!,
          spaceRequirementFrameProfile: spaceRequirementFrameProfile!,
          minSpaceBetweenSets: minSpaceBetweenSets!,
          montageplatzInklLagerschraube: montageplatzInklLagerschraube!,
          abstandFluegelaussenkanteZumAntriebB3:
            abstandFluegelaussenkanteZumAntriebB3!,
          withGasSpring: !!withGasSpring,
          freeEntry: !!freeEntry,
          consoleSetApplications: consoleSetApplications,
          maxOpeningAngle: maxOpeningAngle,
          requiresVcdTailPiece: requiresVcdTailPiece,
          cornerAngleLength: cornerAngleLength!,
          // hints: [],
        }),
      );
    }
  }

  function generateConsoleSet(): void {
    if (!driveSeries) {
      throw new Error('Drive series is undefined');
    }

    dispatch(
      createConsoleSet(
        {
          active: isActive,
          preview: isPreview,
          artNr: artNr,
          name: name,
          onlyConsoleSetAssignment: onlyConsoleSetAssignment,
          frameConsole: frameConsole!,
          sashConsole: sashConsole!,
          aluminium: isAlu,
          wood: isWood,
          glass: isGlass,
          steel: isSteel,
          woodAluminium: isWoodAluminium,
          plastic: isPlastic,
          driveSeries: driveSeries,
          driveFamilies: driveFamilies,
          assemblyPositionFrame: isAssemblyPositionFrame,
          assemblyPositionSash: isAssemblyPositionSash,
          openingDirectionInwards: isOpeningDirectionInwards,
          openingDirectionOutwards: isOpeningDirectionOutwards,
          montageOnTop: isMontageOnTop,
          assemblyIntegrated: isAssemblyIntegrated,
          assemblyRotatable: isAssemblyRotatable,
          spaceRequirementSashProfile: spaceRequirementSashProfile!,
          spaceRequirementFrameProfile: spaceRequirementFrameProfile!,
          minSpaceBetweenSets: minSpaceBetweenSets!,
          montageplatzInklLagerschraube: montageplatzInklLagerschraube!,
          abstandFluegelaussenkanteZumAntriebB3:
            abstandFluegelaussenkanteZumAntriebB3!,
          rangeOfApplication: props.rangeOfApplication,
          withGasSpring: !!withGasSpring,
          freeEntry: !!freeEntry,
          consoleSetApplications: consoleSetApplications ?? {},
          maxOpeningAngle: maxOpeningAngle,
          requiresVcdTailPiece: requiresVcdTailPiece,
          cornerAngleLength: cornerAngleLength,
        },
        props.rangeOfApplication,
      ),
    );
  }

  return (
    <Dialog
      key={props.dialogIsShown.toString()}
      cancelAction={() => {
        resetInputFields();
        props.setSelectedConsoleSet(undefined);
      }}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      componentClass="full-view-dialog"
      headingText={
        props.selectedConsoleSet
          ? `Konsolenset ${props.selectedConsoleSet.name} bearbeiten`
          : 'Neues Konsolenset anlegen'
      }
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setSelectedConsoleSet(undefined);
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedConsoleSet
                ? editConsoleSet()
                : generateConsoleSet();
              props.setDialogIsShown(false);
              props.setSelectedConsoleSet(undefined);
            }
          : undefined,
        primaryActionLabelText: props.selectedConsoleSet
          ? 'Speichern'
          : 'Anlegen',
      }}
    >
      <ConsoleSetDataDialogTabNav
        rangeOfApplication={props.rangeOfApplication}
      />
      <Routes>
        <Route
          path={'/general/*'}
          element={
            <ConsoleSetGeneralDataDialog
              setArtNr={setArtNr}
              artNr={artNr}
              setIsActive={setIsActive}
              isActive={isActive}
              setIsPreview={setIsPreview}
              isPreview={isPreview}
              setName={setName}
              name={name}
              setFrameConsole={setFrameConsole}
              frameConsole={frameConsole}
              setSashConsole={setSashConsole}
              sashConsole={sashConsole}
              setOnlyForConsoleSetAssignment={setOnlyConsoleSetAssignment}
              onlyForConsoleSetAssignment={onlyConsoleSetAssignment}
              allFrameConsoles={allFrameConsoles()}
              allSashConsoles={allSashConsoles()}
              selectedConsoleSet={props.selectedConsoleSet}
            />
          }
        />

        <Route
          path="/properties"
          element={
            <ConsoleSetPropertiesDataDialog
              selectedConsoleSet={props.selectedConsoleSet}
              driveSeries={driveSeries}
              setDriveSeries={setDriveSeries}
              isAluminium={isAlu}
              setIsAluminium={setIsAlu}
              isWood={isWood}
              setIsWood={setIsWood}
              isSteel={isSteel}
              setIsSteel={setIsSteel}
              isGlass={isGlass}
              setIsGlass={setIsGlass}
              isWoodAluminium={isWoodAluminium}
              setIsWoodAluminium={setIsWoodAluminium}
              isPlastic={isPlastic}
              setIsPlastic={setIsPlastic}
              isAssemblyIntegrated={isAssemblyIntegrated}
              setIsAssemblyIntegrated={setIsAssemblyIntegrated}
              isAssemblyPositionFrame={isAssemblyPositionFrame}
              setIsAssemblyPositionFrame={setIsAssemblyPositionFrame}
              isAssemblyPositionSash={isAssemblyPositionSash}
              setIsAssemblyPositionSash={setIsAssemblyPositionSash}
              isAssemblyRotatable={isAssemblyRotatable}
              setIsAssemblyRotatable={setIsAssemblyRotatable}
              isMontageOnTop={isMontageOnTop}
              setIsMontageOnTop={setIsMontageOnTop}
              isOpeningDirectionInwards={isOpeningDirectionInwards}
              setIsOpeningDirectionInwards={setIsOpeningDirectionInwards}
              isOpeningDirectionOutwards={isOpeningDirectionOutwards}
              setIsOpeningDirectionOutwards={setIsOpeningDirectionOutwards}
              withGasSpring={!!withGasSpring}
              setWithGasSpring={setWithGasSpring}
              freeEntry={!!freeEntry}
              setFreeEntry={setFreeEntry}
              consoleSetApplications={consoleSetApplications}
              setConsoleSetApplications={setConsoleSetApplications}
              maxOpeningAngle={maxOpeningAngle}
              setMaxOpeningAngle={setMaxOpeningAngle}
              requiresVCDTailPiece={requiresVcdTailPiece}
              setRequiresVCDTailPiece={setRequiresVcdTailPiece}
              driveFamilies={driveFamilies}
              setDriveFamilies={setDriveFamilies}
            />
          }
        />

        <Route
          path="/eckdaten"
          element={
            <ConsoleSetMeasurementDataDialog
              abstandFluegelaussenkanteZumAntriebB3={
                abstandFluegelaussenkanteZumAntriebB3
              }
              setAbstandFluegelaussenkanteZumAntriebB3={
                setAbstandFluegelaussenkanteZumAntriebB3
              }
              minSpaceBetweenSets={minSpaceBetweenSets}
              setMinSpaceBetweenSets={setMinSpaceBetweenSets}
              montageplatzInklLagerschraube={montageplatzInklLagerschraube}
              setMontageplatzInklLagerschraube={
                setMontageplatzInklLagerschraube
              }
              spaceRequirementFrameProfile={spaceRequirementFrameProfile}
              setSpaceRequirementFrameProfile={setSpaceRequirementFrameProfile}
              spaceRequirementSashProfile={spaceRequirementSashProfile}
              setSpaceRequirementSashProfile={setSpaceRequirementSashProfile}
              cornerAngleLength={cornerAngleLength}
              setCornerAngleLength={setCornerAngleLength}
            />
          }
        />
      </Routes>
    </Dialog>
  );
}

interface ConsoleAreaConsoleSetsDataViewProps {
  rangeOfApplication: RangeOfApplication;
}

const ConsoleAreaConsoleSetsDataView: FC<
  React.PropsWithChildren<ConsoleAreaConsoleSetsDataViewProps>
> = props => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const consoleSets = useSelector<AdminState, ConsoleSet[]>(state =>
    props.rangeOfApplication === RangeOfApplication.FACADE
      ? state.adminFacade.consoleSets
      : state.adminRoof.consoleSets,
  );
  const navigate = useNavigate();

  const [selectedConsoleSet, setSelectedConsoleSet] = useState<
    undefined | ConsoleSet
  >(undefined);

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    consoleSets,
    searchString,
    ['artNr', 'name'],
    consoleSet => [consoleSet.sashConsole.name, consoleSet.frameConsole.name],
  );

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function navigateToDefaultTab(): void {
    navigate(
      `/admin/${props.rangeOfApplication.toLowerCase()}/consoles/console-sets/general`,
    );
  }

  function enableEditConsoleSet(consoleSet: ConsoleSet): void {
    setSelectedConsoleSet(consoleSet);
    setDialogIsShown(true);
    navigateToDefaultTab();
  }

  function switchActiveAction(set: ConsoleSet): void {
    dispatch(changeConsoleSet({ ...set, active: !set.active }));
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setSelectedConsoleSet(undefined);
    navigate(
      `/admin/${props.rangeOfApplication.toLowerCase()}/consoles/console-sets/general`,
    );
  }

  //generisch? <T extends {active:boolean; id:number ...}>
  function getCurrentTableContent(): ConsoleSet[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <>
      <AdminConsoleSetDialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
        selectedConsoleSet={selectedConsoleSet}
        setSelectedConsoleSet={setSelectedConsoleSet}
        rangeOfApplication={props.rangeOfApplication}
        key={selectedConsoleSet?.id}
      />
      <div className="sub-header">
        <div className="sub-header__title"> Konsole</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Konsolenset suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>

      <div className="console-set-data">
        <Table subNavigation={true}>
          <TableHeader>
            <th>Artikelnummer</th>
            <th>Bezeichnung</th>
            <th>Flügelkonsole</th>
            <th>Rahmenkonsole</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(set => (
              <TableRow>
                <td>{set.artNr}</td>
                <td>{set.name}</td>
                <td>{set.sashConsole.artNr}</td>
                <td>{set.frameConsole.artNr}</td>
                <td>
                  <Switch
                    key={set.id}
                    turnedOn={set.active}
                    onChange={() => switchActiveAction(set)}
                  />
                </td>
                <td>
                  <button
                    onClick={() => {
                      enableEditConsoleSet(set);
                    }}
                  >
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};

export default ConsoleAreaConsoleSetsDataView;
