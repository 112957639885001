import { Action, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  ConsoleSet,
  DefaultConsoleSetValues,
  Document,
  DocumentsListsResult,
} from './staticDataReducer';
import { IState } from './store';
import { changeCalculationParameterBulk, Dispatch } from './parametersActions';
import {
  calculating,
  finishedCalculation,
  preSelectConsoleSet,
  PreSelectedConsoleSetAction,
  PreSelectedLockingConsoleAction,
  preSelectLockingConsole,
  selectConsoleSet,
  selectLockingConsole,
  setHighlightedConsoleSet,
  setHighlightedLockingConsole,
  storeSelectedNRWGConsoleSet,
} from './uiStateActions';
import {
  fetchAndStore,
  fetchFromServer,
  fetchWithoutCalculationParameters,
  RequestTypes,
} from './httpClient';
import { ValueKey } from './valueKey';
import { getMarkedDrive, getSelectedWindow } from '../hooks/selectorHooks';
import {
  getAerodynamicDesmoking,
  getGeomtricDesmoking,
} from './desmokingResultActions';
import {
  DriveSeries,
  NrwgManualArticleNumbers,
  RangeOfApplication,
} from './constants';
import { LockingConsoleWithOptionalId } from './admin/adminFacadeReducer';

export interface SetDocumentsListsResult
  extends Action<'SET_DOCUMENTS_LISTS_RESULT'> {
  documentsListsResult: DocumentsListsResult;
}

export interface SetDriveDocumentsAction extends Action<'SET_DRIVE_DOCUMENTS'> {
  documents: Document[];
}

export interface SetConsoleDocuments extends Action<'SET_CONSOLE_DOCUMENTS'> {
  documents: Document[][];
}

export interface SetLockingDocuments extends Action<'SET_LOCKING_DOCUMENTS'> {
  documents: Document[];
}

export interface SetLockingConsoleDocuments
  extends Action<'SET_LOCKING_CONSOLE_DOCUMENTS'> {
  documents: Document[];
}

export interface StoreDriveSeriesAction
  extends Action<'STORE_DRIVE_SERIES_ACTION'> {
  driveSeries: DriveSeries[];
}

export interface SetNrwgManualDocuments
  extends Action<'SET_NRWG_MANUAL_DOCUMENTS'> {
  documents: Document[];
}

export interface SetDefaultConsoleSetAction
  extends Action<'SET_DEFAULT_CONSOLE_SET'> {
  defaultConsoleSetValues: DefaultConsoleSetValues;
}

export interface StoreNRWGConsoleSetCandidatesAction
  extends Action<'STORE_NRWG_CONSOLE_SET_CANDICATES'> {
  consoleSets: ConsoleSet[];
}

export interface StoreSuitableConsoleSetsAction
  extends Action<'STORE_SUITABLE_CONSOLE_SETS'> {
  suitableConsoleSets: ConsoleSet[][];
}

export interface StoreSuitableLockingConsolesAction
  extends Action<'STORE_SUITABLE_LOCKING_CONSOLES'> {
  suitableLockingConsoles: LockingConsoleWithOptionalId[];
}

export type ResetDocuments = Action<'RESET_DOCUMENTS'>;

export type StaticDataActions =
  | SetDriveDocumentsAction
  | SetNrwgManualDocuments
  | SetConsoleDocuments
  | SetLockingDocuments
  | SetLockingConsoleDocuments
  | SetDefaultConsoleSetAction
  | StoreNRWGConsoleSetCandidatesAction
  | StoreSuitableConsoleSetsAction
  | StoreSuitableLockingConsolesAction
  | SetDocumentsListsResult
  | ResetDocuments
  | StoreDriveSeriesAction;

export const setDocumentsListsResult = (
  documentsListsResult: DocumentsListsResult,
): SetDocumentsListsResult => {
  return {
    type: 'SET_DOCUMENTS_LISTS_RESULT',
    documentsListsResult: documentsListsResult,
  };
};

export const setConsoleDocuments = (
  documents: Document[][],
): SetConsoleDocuments => {
  return { type: 'SET_CONSOLE_DOCUMENTS', documents: documents };
};

export const setLockingDocuments = (
  documents: Document[],
): SetLockingDocuments => {
  return { type: 'SET_LOCKING_DOCUMENTS', documents: documents };
};

export const setLockingConsoleDocuments = (
  documents: Document[],
): SetLockingConsoleDocuments => {
  return { type: 'SET_LOCKING_CONSOLE_DOCUMENTS', documents: documents };
};

export const setNrwgManualDocuments = (
  documents: Document[],
): SetNrwgManualDocuments => {
  return { type: 'SET_NRWG_MANUAL_DOCUMENTS', documents: documents };
};

export function setDriveDocuments(
  documents: Document[],
): SetDriveDocumentsAction {
  return { type: 'SET_DRIVE_DOCUMENTS', documents: documents };
}

export function setDefaultConsoleSetValues(
  defaultConsoleSet: DefaultConsoleSetValues,
): SetDefaultConsoleSetAction {
  return {
    type: 'SET_DEFAULT_CONSOLE_SET',
    defaultConsoleSetValues: defaultConsoleSet,
  };
}

export function storeNRWGConsoleSetCandidates(
  consoleSets: ConsoleSet[],
): StoreNRWGConsoleSetCandidatesAction {
  return { type: 'STORE_NRWG_CONSOLE_SET_CANDICATES', consoleSets };
}

export function storeDriveSeries(
  driveSeries: DriveSeries[],
): StoreDriveSeriesAction {
  return { type: 'STORE_DRIVE_SERIES_ACTION', driveSeries: driveSeries };
}

export function fetchDriveSeries(): ThunkAction<
  Promise<void>,
  IState,
  void,
  StoreDriveSeriesAction
> {
  return async (dispatch): Promise<void> => {
    const driveSeries = await fetchWithoutCalculationParameters<DriveSeries>(
      '/driveSeries',
    )!;

    dispatch(storeDriveSeries(driveSeries));
  };
}

export function setSuitableConsoleSets(
  suitableConsoleSets: ConsoleSet[][],
): ThunkAction<
  Promise<void>,
  IState,
  void,
  StoreSuitableConsoleSetsAction | PreSelectedConsoleSetAction
> {
  return async (dispatch): Promise<void> => {
    if (!suitableConsoleSets.forEach) {
      return;
    }

    suitableConsoleSets.forEach((consoleSets, index) => {
      if (consoleSets.length === 1) {
        dispatch(preSelectConsoleSet(consoleSets[0]!, index));
      }
    });
    dispatch(storeSuitableConsoleSets(suitableConsoleSets));
  };
}

export function setSuitableLockingConsoles(
  suitableLockingConsoles: LockingConsoleWithOptionalId[],
): ThunkAction<
  Promise<void>,
  IState,
  void,
  StoreSuitableLockingConsolesAction | PreSelectedLockingConsoleAction
> {
  return async (dispatch): Promise<void> => {
    if (!suitableLockingConsoles.forEach) {
      return;
    }

    suitableLockingConsoles.forEach((lockingConsoles, index) => {
      dispatch(preSelectLockingConsole(lockingConsoles!, index));
    });
    dispatch(storeSuitableLockingConsoles(suitableLockingConsoles));
  };
}

export function storeSuitableConsoleSets(
  suitableConsoleSets: ConsoleSet[][],
): StoreSuitableConsoleSetsAction {
  return {
    type: 'STORE_SUITABLE_CONSOLE_SETS',
    suitableConsoleSets: suitableConsoleSets,
  };
}

export function storeSuitableLockingConsoles(
  suitableLockingConsoles: LockingConsoleWithOptionalId[],
): StoreSuitableLockingConsolesAction {
  return {
    type: 'STORE_SUITABLE_LOCKING_CONSOLES',
    suitableLockingConsoles: suitableLockingConsoles,
  };
}

export function resetDocuments(): ResetDocuments {
  return {
    type: 'RESET_DOCUMENTS',
  };
}

export function getDriveDocuments(
  itemNumber: string,
): ThunkAction<Promise<void>, IState, void, SetDriveDocumentsAction> {
  return async (dispatch, getState): Promise<void> => {
    fetchProductDocuments(itemNumber, getState(), products => {
      dispatch(setDriveDocuments(products));
    });
  };
}

export function getConsoleDocuments(
  itemNumbers: string[],
): ThunkAction<Promise<void>, IState, void, SetConsoleDocuments> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const documents = await Promise.all(
      itemNumbers.map(async itemNumber =>
        fetchProductDocuments(itemNumber, getState(), () => false),
      ),
    );

    dispatch(setConsoleDocuments(documents));
  };
}

export function getLockingConsoleDocument(
  itemNumber: string,
): ThunkAction<Promise<void>, IState, void, SetConsoleDocuments> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const documents = await fetchProductDocuments(
      itemNumber,
      getState(),
      () => false,
    );
    dispatch(setLockingConsoleDocuments(documents));
  };
}

export function getLockingDriveDocuments(
  itemNumber: string,
): ThunkAction<Promise<void>, IState, void, SetLockingDocuments> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    await fetchProductDocuments(itemNumber, getState(), products =>
      dispatch(setLockingDocuments(products)),
    );
  };
}

export function getLockingConsoleDocuments(
  itemNumber: string,
): ThunkAction<Promise<void>, IState, void, SetLockingConsoleDocuments> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    await fetchProductDocuments(itemNumber, getState(), products =>
      dispatch(setLockingConsoleDocuments(products)),
    );
  };
}

export function getNrwgManualDocuments(): ThunkAction<
  Promise<void>,
  IState,
  void,
  SetNrwgManualDocuments
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const itemNumber =
      getState().parameters.rangeOfApplication.value ===
      RangeOfApplication.FACADE
        ? NrwgManualArticleNumbers.FACADE
        : NrwgManualArticleNumbers.ROOF;
    await fetchProductDocuments(itemNumber, getState(), products =>
      dispatch(setNrwgManualDocuments(products)),
    );
  };
}

async function fetchProductDocuments(
  itemNumber: string,
  state: IState,
  updateStore: (documents: Document[]) => void,
): Promise<Document[]> {
  const documents = await fetchFromServer<Document[]>(
    RequestTypes.PRODUCT_DOCUMENTS,
    state,
    undefined,
    {
      id: itemNumber,
    },
  );
  if (documents) {
    updateStore(documents as Document[]);
  }

  return documents as Document[];
}

export function getDocumentsListsResult(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.DOCUMENTS_LISTS));
    await fetchAndStore<DocumentsListsResult>(
      RequestTypes.DOCUMENTS_LISTS,
      documentListsResult =>
        dispatch(setDocumentsListsResult(documentListsResult)),
      getState,
    ).finally(() =>
      dispatch(finishedCalculation(RequestTypes.DOCUMENTS_LISTS)),
    );
  };
}

export function getNRWGConsoleSet(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const window = getSelectedWindow(getState());
    if (!window?.nrwg) {
      return;
    }

    if (!window.consoleSets || window.consoleSets.length === 0) {
      dispatch(calculating(RequestTypes.NRWG_CONSOLE_SETS));
    }

    return fetchAndStore(
      RequestTypes.NRWG_CONSOLE_SETS,
      async (response: ConsoleSet[]) => {
        if (response.length) {
          dispatch(storeNRWGConsoleSetCandidates(response));
          if (
            response.length < 2 &&
            (!window.consoleSets || window.consoleSets.length === 0)
          ) {
            await dispatch(selectConsoleSet([response[0]]));
            dispatch(storeSelectedNRWGConsoleSet(response[0]));
          }
        }
      },
      getState,
      { args: { selectedDriveId: getMarkedDrive(getState())?.driveId } },
    ).then(() => {
      dispatch(getAerodynamicDesmoking());
      dispatch(getGeomtricDesmoking());
      dispatch(finishedCalculation(RequestTypes.NRWG_CONSOLE_SETS));
    });
  };
}

export function getDefaultConsole(): ThunkAction<
  Promise<void>,
  IState,
  void,
  SetDefaultConsoleSetAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    if (!getState().ui.inputFromLogikal) {
      fetchAndStore(
        RequestTypes.DEFAULT_CONSOLE_SET_VALUES,
        (consoleSet: DefaultConsoleSetValues) => {
          dispatch(
            changeCalculationParameterBulk({
              [ValueKey.VALUEKEY_ASSEMBLY_SPACE_FRAME]:
                consoleSet.assemblySpaceFrameProfile,
              [ValueKey.VALUEKEY_ASSEMBLY_SPACE_SASH_PROFILE]:
                consoleSet.assemblySpaceSashProfile,
            }),
          );
          dispatch(setDefaultConsoleSetValues(consoleSet));
          dispatch(getSuitableConsoleSets());
        },
        getState,
        {
          args: { selectedDriveId: getMarkedDrive(getState())?.driveId },
        },
      );
    }
  };
}

export function getSuitableConsoleSets(): ThunkAction<
  Promise<void>,
  IState,
  void,
  StoreSuitableConsoleSetsAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const selectedDriveId = getMarkedDrive(getState())?.driveId;
    if (selectedDriveId) {
      if (getSelectedWindow(getState())?.nrwg) {
        return;
      }

      dispatch(calculating(RequestTypes.CONSOLE_SETS));

      await fetchAndStore(
        RequestTypes.CONSOLE_SETS,
        (consoleSets: ConsoleSet[][]) => {
          const selectedWindow = getSelectedWindow(getState());
          const selectedConsoleSets = selectedWindow
            ? selectedWindow.consoleSets
            : getState().ui.selectedConsoleSets;

          dispatch(
            setHighlightedConsoleSet(
              selectedConsoleSets?.[0] ?? consoleSets[0]?.[0],
              0,
            ),
          );
          if (selectedConsoleSets) {
            dispatch(
              selectConsoleSet(selectedConsoleSets, {
                skipStoreOnServer: !!selectedConsoleSets,
              }),
            );
          }

          dispatch(setSuitableConsoleSets(consoleSets));
          dispatch(finishedCalculation(RequestTypes.CONSOLE_SETS));
        },
        getState,
        {
          args: { selectedDriveId: selectedDriveId },
          fallbackValue: [],
        },
      );

      return fetchAndStore(
        RequestTypes.LOCKING_CONSOLES,
        (lockingConsoles: LockingConsoleWithOptionalId[]) => {
          const selectedWindow = getSelectedWindow(getState());
          const selectedLockingConsole = selectedWindow
            ? selectedWindow.lockingConsole
            : getState().ui.selectedLockingConsole;

          dispatch(
            setHighlightedLockingConsole(
              selectedLockingConsole ?? lockingConsoles[0],
            ),
          );
          if (selectedLockingConsole) {
            dispatch(
              selectLockingConsole(selectedLockingConsole, {
                skipStoreOnServer: !!selectedLockingConsole,
              }),
            );
          }

          dispatch(setSuitableLockingConsoles(lockingConsoles));
          dispatch(finishedCalculation(RequestTypes.LOCKING_CONSOLES));
        },
        getState,
        {
          args: { selectedDriveId: selectedDriveId },
          fallbackValue: [],
        },
      );
    }
  };
}
